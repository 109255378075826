$(document).ready(function() {

    // Start by clearing out all session level variables so we're not carrying anything over from the last user to login 
    sessionStorage.clear();

    $("#gatewaylogin").prop( "disabled", true);
    $("#emailaddress").focus(); 

    vid = document.getElementById("myVideo"); 
    icon = $(this).find("i");
    later = moment().utc().add(1, "Week").format("ddd, D MMM YYYY HH:mm:ss z")

    switch( window.location.hostname.split(".")[0].split(":")[0].toLowerCase())
    {
        case "localhost":
            $(".auth-card").css( "background-color", "#28527da0");
            $("#auth-card-subtitle").html( "Industrial Networks Gateway DEV");
            break;
        case "gatewaynext":
            $(".auth-card").css( "background-color", "#2d1b5a80");
            $("#auth-card-subtitle").html( "Industrial Networks Gateway NEXT");
            break;
        case "gatewaytest":
            $(".auth-card").css( "background-color", "#ff00007a");
            $("#auth-card-subtitle").html( "Industrial Networks Gateway TEST");
            break;
    }

    /**
     * Wrapper for Session Storage Get Item function so we can, for example, add user id to path if necessary or change to some other storage vehicle
     * 
     * @param {String} nam - name (key) of value to return
     */
    window.getSession = function (nam) {
        return sessionStorage.getItem(nam);
    }

    /**
     * Wrapper for Session Storage Set Item function so we can add user id to path if necessary
     * 
     * @param {String} nam - name (key) of item to store
     * @param {String} val - String to store as the value
     */
    window.setSession = function (nam, val) {
        return sessionStorage.setItem(nam, val);
    }

    function getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      }        

    function turnVideoOff()
    {
        vid.pause();
        icon.removeClass( "fe-video").addClass( "fe-video-off");
        vid.hidden = true;
        document.cookie = "loginvid=false; expires=" + later + "; path=/";
    }

    function turnVideoOn()
    {
        icon.removeClass( "fe-video-off").addClass( "fe-video");
        vid.hidden = false;
        vid.play();
        document.cookie = "loginvid=true; expires=" + later + "; path=/";
    }

    if ( getCookie( "loginvid") == "false")
    {
        turnVideoOff()
    }
    else
    {
        turnVideoOn()
    }

    $("#vidbtn").on( "click", function() 
    {
        state = icon.hasClass( "fe-video");
        if ( state)
        {
            turnVideoOff();
        }
        else
        {
            turnVideoOn();
        }
    });


    loginbutton = Ladda.create( document.querySelector('#gatewaylogin'));


    // Submit the form when user presses Return on the Password field
    $("#password").keypress(function (event)
    {
        var keycode = (event.keyCode ? event.keyCode : event.which);
        if(keycode == '13' && goodValues())
        {
            event.preventDefault();
            $('#gatewaylogin').click();
        }
    });

    $("#password").change( function() {
        setLoginButtonState();
    })


    $("#emailaddress").keypress(function (event)
    {
        var keycode = (event.keyCode ? event.keyCode : event.which);
        if(keycode == '13')
        {
            event.preventDefault();
            $("#password").focus(); 
        }
    });

    $("#emailaddress").change( function() {
        setLoginButtonState();
    })

    $('#gatewaylogin')
        .on('click', function (e) {
            console.log( "logging in");

            if ( goodValues())
            {
                $("#gatewaylogin").prop( "disabled", true);

                email = $("#emailaddress").val();
                password = $("#password").val();
                url = "/api/v1/authenticate/"

                var params = 
                { 
                    "email":email, 
                    "pass":password
                };

                loginbutton.start();
                td = new DataClass();
                td
                    .method( "POST")
                    .parameters( params)
                    .url( url)
                    .timeout( 10000)
                    .contentType( "form")
                    .onData( function(data) {
                        console.log( data);
                        if ( data.status == true) 
                        {
                            // G.accessToken = this.xhr().getResponseHeader("access-token");
                            // G.refreshToken = this.xhr().getResponseHeader("refresh-token");

                            // sessionStorage.setItem( "accessToken", G.accessToken);
                            // sessionStorage.setItem( "refreshToken", G.refreshToken);

                            // Store the Tokens in sessionStorage to pass to Gateway main app. 
                            sessionStorage.setItem( "accessToken",  this.xhr().getResponseHeader("access-token"));
                            sessionStorage.setItem( "refreshToken", this.xhr().getResponseHeader("refresh-token"));

                            if ( data.data.avatar == "null" && data.data.initials == null) data.data.avatar = "inet-logo-square.png";

                            // Store User information in SessionStorage
                            sessionStorage.setItem( "email",        data.data.email);
                            sessionStorage.setItem( "firstname",    data.data.firstname);
                            sessionStorage.setItem( "lastname",     data.data.lastname);
                            sessionStorage.setItem( "title",        data.data.title);
                            sessionStorage.setItem( "defplant",     data.data.plant);
                            sessionStorage.setItem( "lastloginat",  data.data.lastloginat);
                            sessionStorage.setItem( "avatar",       data.data.avatar);
                            sessionStorage.setItem( "id",           data.data.id);
                            sessionStorage.setItem( "userid",       data.data.user_id);
                            sessionStorage.setItem( "initials",     data.data.initials);
                            sessionStorage.setItem( "request",      data.data.request);
                            sessionStorage.setItem( "sessionid",    data.data.session_id);
                            sessionStorage.setItem( "lastplant",    data.data.lastplant);
                            sessionStorage.setItem( "system_code",  data.data.system_code);
                            sessionStorage.setItem( "settings",     JSON.stringify( data.data.settings));
                            sessionStorage.setItem( "cw_member_id", data.data.cw_member_id);
                            console.log( "Gateway Session ID: " + data.data.session_id);

                            // Jump into the Gateway app
                            window.location.assign("gateway.html");
                        }
                        else
                        {
                            console.log( data.data)
                            $("#gatewaylogin").prop( "disabled", true);
                            $("#loginmessage").html( data.data).show();
                            loginbutton.stop();
                        }
                    }.bind(td))
                    .onTimeout( function( data) {
                        msg = "We're having some difficulting logging in right now. Please try again. If you continue to get this error please contact support.";
                        console.log( msg)
                        $("#gatewaylogin").prop( "disabled", true);
                        $("#loginmessage").html( msg).show();
                        loginbutton.stop();
                    }.bind( td))
                    .onFailure( function( data) {
                        console.log( "Data Error: " + data);
                        console.log( url);
                        console.log( params);
                        console.log( data.data)
                        loginbutton.stop();
                    }.bind(td))
                    .gather();
                e.preventDefault();
            }    
        });


        function goodValues()
        {
            if ( $("#password").val() != "" && $("#emailaddress").val() != "" )
            {
                return true;
            }
            else
            {
                return false;
            }
        }
    
        function setLoginButtonState()
        {
            if ( goodValues()) 
            {
                $("#gatewaylogin").prop( "disabled", false);
            }
            else
            {
                $("#gatewaylogin").prop( "disabled", true);
            }
        }
    
    
        setLoginButtonState();
});
